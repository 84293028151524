import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend,
} from 'recharts';
import { AxiosResponse } from 'axios';
import { getColorFromRange } from '../util/ColorUtil';
import { ChartConfig, ChartData } from '../generated';
import { chartApi } from '../util/apis';

type timePeriodType = "ALL" | "TODAY" | "YESTERDAY" | "THISWEEK" | "THISMONTH" | "THISYEAR" | "LASTDAY" | "LASTWEEK" | "LASTMONTH" | "LASTYEAR";

interface SensorChartProps {
  sensorId?: string,
  sensorType?: "DEWPOINT" | "ENERGY" | "HUMIDITY" | "LUMINISCENCE" | "POWER" | "PRESSURE" | "RAIN" | "RAINRATE" | "TEMPERATURE" | "WIND"
  sensorClass?: "BRINE" | "HEATING_ACC" | "HEATING_ACC_HP" | "HEATING_FH" | "HEATING_FP_ACC" | "HEATING_HP" | "OUTSIDE" | "ROOM" | "SOLAR" | "WATER"
  timePeriod: timePeriodType
  byType: "byclass" | "bytype" | "bysensor"
}

export const SimpleLineChart: React.FC<SensorChartProps> = (props) => {
  const [dataKeys, setDatakeys] = useState<string[]>([])
  const [chartData, setData] = useState<ChartData[]>([])
  const getDataset = useCallback(() => {
    const apiCall = (() => {
      switch (props.byType) {
        case 'byclass': return chartApi.getDatasetBySensorClassUsingGET(props.sensorClass!, props.timePeriod)
        case 'bytype': return chartApi.getDatasetBySensorTypeUsingGET(props.sensorType!, props.timePeriod)
        case 'bysensor': return chartApi.getDatasetBySensorUsingGET(props.sensorId!, props.timePeriod)
        default: return chartApi.getDatasetBySensorClassUsingGET(props.sensorClass!, props.timePeriod);
      }
    })();

    apiCall.then((response: AxiosResponse<ChartConfig>) => {
      setDatakeys(response.data.keys!)
      const responseChartData = response.data.chartData!;
      console.log(responseChartData)
      setData(responseChartData)
    })
  }, [props.byType, props.sensorClass, props.sensorId, props.sensorType, props.timePeriod])

  useEffect(() => {
    getDataset()
  }, [getDataset, props.byType, props.sensorClass, props.sensorId, props.sensorType, props.timePeriod]);

  useEffect(() => {
    const interval = setInterval(() => {
      getDataset()
    }, 60000);
    return () => clearInterval(interval);
  }, [getDataset, props.byType, props.sensorClass, props.sensorId, props.sensorType, props.timePeriod]);

  const getDataKey = (index: number) => {
    return 'data[' + index + ']'
  }
  const getFormatter = () => {
    if (["TODAY", "YESTERDAY"].includes(props.timePeriod)) {
      return 'HH:mm'
    } else {
      return 'MM-dd  HH:mm'
    }
  }

  return (
    <div style={{ width: '100%', height: 310 }}>
      <ResponsiveContainer>
        <LineChart data={chartData}
          margin={{
            top: 0, right: 0, left: -25, bottom: 0,
          }}>
          <XAxis scale="time" type="number" dataKey="timestamp" domain={['auto', 'auto']}
            tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')} />
          <YAxis />
          <Tooltip labelFormatter={(unixTime) => moment(unixTime).format('HH:mm')} />
          <Legend />
          {dataKeys.map((key, index) => (<Line name={key} key={key} connectNulls type="monotone" dataKey={getDataKey(index)} stroke={getColorFromRange(index, dataKeys.length)} dot={false} />))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}


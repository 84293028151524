import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { RainReport } from '../generated';
import { Box, Button, CardActions } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { SimpleLineChart } from './SimpleLineChart';
import { YearByYearChart } from './YearByYearChart';


interface RainCardProps {
    rainReport: RainReport;
}

export function RainCard(props: RainCardProps) {
    const [isFlipped, flip] = useState(false);
    const handleClick = (e: any) => {
        flip(!isFlipped)
    }
    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Regn
                        </Typography>
                        <Typography variant="h5" component={'div'}>
                            I dag: {props.rainReport.today?.value}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary" paragraph>
                            Senaste dygnet: {props.rainReport.last24h?.value} <br />
                            Senaste veckan: {props.rainReport.lastWeek?.value} <br />
                            Senaste månaden: {props.rainReport.lastMonth?.value}
                        </Typography>
                    </Box>
                </CardContent>

            </Card>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <YearByYearChart sensor='ZWayVDev_zway_4-0-49-2' />
                    </Box>
                </CardContent>
                <CardActions><Button onClick={handleClick}><ArrowBackIosNewIcon /></Button></CardActions>
            </Card>
        </ReactCardFlip>

    );
}
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { WindReport } from '../generated';
import { Box, Button, CardActions, Grid } from '@mui/material';
import ReactCardFlip from 'react-card-flip';
import { SimpleLineChart } from './SimpleLineChart';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useState } from 'react';



interface WindCardProps {
    windReport: WindReport;
}

export function WindCard(props: WindCardProps) {
    const [isFlipped, flip] = useState(false);
    const handleClick = (e: any) => {
        flip(!isFlipped)
    }
    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Vind
                        </Typography>
                        <Typography variant="h5" component={'div'}>
                            Just nu: {props.windReport.current?.value}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Min: {props.windReport.min?.value} Max: {props.windReport.max?.value}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <SimpleLineChart timePeriod='LASTWEEK' byType='bysensor' sensorId='ZWayVDev_zway_4-0-49-2' />
                    </Box>
                </CardContent>
                <CardActions><Button onClick={handleClick}><ArrowBackIosNewIcon /></Button></CardActions>
            </Card>
        </ReactCardFlip>

    );
}
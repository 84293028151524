import './App.css';
import { Weather } from './component/Weather';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useState } from 'react';
import { Facebook, Home, Instagram } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PictureViewer from './component/PictureViewer';
import { Container } from '@mui/material';

function App() {
  const [value, setValue] = useState("weather");
  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" component={'div'} sx={{ flexGrow: 1 }}>
            Anviken
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        {value === "weather" && <Weather />}
        {value === "pictures" && <PictureViewer />}
      </Container>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (newValue.match(/http.*/)) {
            window.location.href = newValue;
          }
          setValue(newValue);

        }}
        sx={{ position: 'fixed', bottom: 0, width: 1.0 }}
      >
        <BottomNavigationAction label="Väder" value={"weather"} icon={<Home />} />
        <BottomNavigationAction label="Facebook" value={"https://www.facebook.com/groups/460404207478861"} icon={<Facebook />} />
        <BottomNavigationAction label="Instagram" value={"https://www.instagram.com/explore/tags/anviken/"} icon={<Instagram />} />
      </BottomNavigation>
    </>
  );
}

export default App;

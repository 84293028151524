import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import { weatherReducer, weatherSaga } from './weather';
import { spawn } from 'redux-saga/effects';

const sagaMiddleware = createSagaMiddleware()

const rootSaga = function* () {
    yield spawn(weatherSaga)
}

export const rootReducer = combineReducers({
    weather: weatherReducer,

})

export const store = createStore(rootReducer,
    composeWithDevTools(
        applyMiddleware(
            sagaMiddleware,// ... other middlewares ...
        ),
    ),
)

sagaMiddleware.run(rootSaga)

export default store

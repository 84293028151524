import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { WiRain, WiThermometer, WiStrongWind } from "react-icons/wi";
import { selectWeatherReport, weatherActions } from '../saga/weather';
import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { TemperatureCard } from './TemperatureCard';
import { WindCard } from './WindCard';
import { RainCard } from './RainCard';
import { OtherInfoCard } from './OtherInfoCard';
import { makeStyles } from '@mui/styles';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  scrollButtons: {
    "&.Mui-disabled": {
      opacity: 0.3
    }
  }
});

export const Weather: React.FC = () => {
  const weatherReport = useSelector(selectWeatherReport);
  const dispatch = useDispatch();
  useEffect(() => { dispatch(weatherActions.fetchWeather.request()) }, [dispatch])
  const theme = useTheme();
  const matches = useMediaQuery('(min-height:600px)');
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const classes = useStyles();
  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        spacing={0}>
        <Grid item xs={matches ? 12 : 2}>
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example"
            centered
            classes={classes}
            orientation={matches ? 'horizontal' : 'vertical'}>
            <Tab label={weatherReport.temperatureReport!.current?.value} icon={<WiThermometer />} {...a11yProps(0)} />
            <Tab label={weatherReport.windReport!.current?.value} icon={<WiStrongWind />} {...a11yProps(1)} />
            <Tab label={weatherReport.rainReport!.today?.value} icon={<WiRain />} {...a11yProps(2)} />
            <Tab label="..."  {...a11yProps(3)} />
          </Tabs>
        </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <TabPanel value={value} index={0}>
            <TemperatureCard temperatureReport={weatherReport.temperatureReport!} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WindCard windReport={weatherReport.windReport!} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RainCard rainReport={weatherReport.rainReport!} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <OtherInfoCard weatherReport={weatherReport} />
          </TabPanel>
        </Grid>
      </Grid>


    </Box>
  );
}
import { takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { weatherApi } from "../util/apis";
import { initialWeatherReportState } from "./state/initialState";
import { RootState, WeatherState } from "./state/state";
import { WeatherReport } from "../generated";

export const weatherActions = {
    fetchWeather: createAsyncAction(
        'FETCH_WEATHER_REQUEST',
        'FETCH_WEATHER_SUCCESS',
        'FETCH_WEATHER_FAILURE'
    )<undefined, WeatherReport, Error>()
};

export type WeatherAction = ActionType<typeof weatherActions>;
export const weatherReducer = createReducer<WeatherState, WeatherAction>({
    weatherReport: initialWeatherReportState
}).handleAction(weatherActions.fetchWeather.success, (state, action) => ({
    ...state,
    weatherReport: action.payload
}));

export const weatherSaga = function* () {
    yield takeLatest(weatherActions.fetchWeather.request, function* () {
        try {
            const weatherReport: AxiosResponse<WeatherReport> = yield weatherApi.getWeatherReportUsingGET();
            yield put(weatherActions.fetchWeather.success(weatherReport.data))
        } catch (error) {
            console.log("Error")
        }

    });
};
export const selectWeatherReport = (state: RootState) => state.weather.weatherReport;

import { WeatherReport } from "../../generated"
import { WeatherState } from "./state"

export const initialWeatherReportState: WeatherReport = {
  temperatureReport: {
    current: { value: '...°C' },
    max: { value: '...°C' },
    min: { value: '...°C' }
  },
  rainReport: {
    today: { value: '...mm' },
    last24h: { value: '...mm' },
    lastWeek: { value: '...mm' },
    lastMonth: { value: '...mm' }
  },
  windReport: {
    current: { value: '...m/s' },
    max: { value: '...m/s' },
    min: { value: '...m/s' }
  },
  humidity: { value: '...%' },
  dewPoint: { value: '...°C' },
  barometer: { value: '...kPa' }
}

export const initialState: WeatherState = {
  weatherReport: initialWeatherReportState
}

export const shaderBYR = (value: number, blue: number, yellow: number, red: number) => {
	var r, g, b, calctemp;
	if (value <= yellow) {
		calctemp = (value - blue) * (256 / (yellow - blue));
		r = 0 + Math.round(calctemp);
		g = 0 + Math.round(calctemp);
		b = 256 - Math.round(calctemp);
	} else {
		calctemp = (value - yellow) * (256 / (red - yellow));
		r = 256;
		g = 256 - Math.round(calctemp);
		b = 0
	}
	return "rgb(" + r + "," + g + "," + b + ")";
}
export const shaderBGYR = (value: number, blue: number, green: number, yellow: number, red: number) => {
	var r, g, b, calctemp;
	if (value <= green) {
		calctemp = (value - blue) * (256 / (green - blue));
		r = 0;
		g = 0 + Math.round(calctemp);
		b = 256 - Math.round(calctemp);
	} else if (value <= yellow) {
		calctemp = (value - green) * (256 / (yellow - green));
		r = 0 + Math.round(calctemp);
		g = 256;
		b = 0;
	} else {
		calctemp = (value - yellow) * (256 / (red - yellow));
		r = 256;
		g = 256 - Math.round(calctemp);
		b = 0
	}
	return "rgb(" + r + "," + g + "," + b + ")";
}
export const getColorFromRange = (value: number, length: number) => {
	const maxIndex = length - 1
	if (maxIndex === 0) {
		return "rgb(0,150,0)";
	}
	value = maxIndex - value
	var r, g, b, calctemp;
	var targettemp = length / 3;
	var hightemp = targettemp * 2;
	if (value <= targettemp) {
		calctemp = value * (256 / targettemp);
		r = 0;
		g = 0 + Math.round(calctemp);
		b = 256 - Math.round(calctemp);
	} else if (value <= hightemp) {
		calctemp = (value - targettemp) * (256 / (hightemp - targettemp));
		r = 0 + Math.round(calctemp);
		g = 256;
		b = 0;
	} else {
		calctemp = (value - hightemp) * (256 / (maxIndex - hightemp));
		r = 256;
		g = 256 - Math.round(calctemp);
		b = 0;
	}
	return "rgb(" + r + "," + g + "," + b + ")";
}
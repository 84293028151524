import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TemperatureReport } from '../generated';
import { Box, Button } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';
import { SimpleLineChart } from './SimpleLineChart';
import CardActions from "@mui/material/CardActions"



interface TemperatureCardProps {
    temperatureReport: TemperatureReport;
}

export function TemperatureCard(props: TemperatureCardProps) {

    const [isFlipped, flip] = useState(false);
    const handleClick = (e: any) => {
        flip(!isFlipped)
    }
    return (

        <ReactCardFlip isFlipped={isFlipped}>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom component={'div'}>
                            Dagens Temperatur
                        </Typography>
                        <Typography variant="h5" component={'div'}>
                            Just nu: {props.temperatureReport.current?.value}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Min: {props.temperatureReport.min?.value} Max: {props.temperatureReport.max?.value}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions><Button onClick={handleClick}><ShowChartIcon /></Button></CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <SimpleLineChart timePeriod='LASTDAY' byType='bysensor' sensorId='28.022F57040000' />
                    </Box>
                </CardContent>
                <CardActions><Button onClick={handleClick}><ArrowBackIosNewIcon /></Button></CardActions>
            </Card>
        </ReactCardFlip>


    );
}
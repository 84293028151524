/* tslint:disable */
/* eslint-disable */
/**
 * HomeMonitor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ChartConfig
 */
export interface ChartConfig {
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof ChartConfig
     */
    'chartData'?: Array<ChartData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChartConfig
     */
    'keys'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartData
     */
    'data'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ChartData
     */
    'timestamp'?: number;
}
/**
 * 
 * @export
 * @interface Forecast
 */
export interface Forecast {
    /**
     * 
     * @type {Array<TimeSerie>}
     * @memberof Forecast
     */
    'timeSeries'?: Array<TimeSerie>;
}
/**
 * 
 * @export
 * @interface Parameter
 */
export interface Parameter {
    /**
     * 
     * @type {number}
     * @memberof Parameter
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'levelType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'unit'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Parameter
     */
    'values'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RainReport
 */
export interface RainReport {
    /**
     * 
     * @type {ReportValue}
     * @memberof RainReport
     */
    'last24h'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof RainReport
     */
    'lastMonth'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof RainReport
     */
    'lastWeek'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof RainReport
     */
    'today'?: ReportValue;
}
/**
 * 
 * @export
 * @interface Reading
 */
export interface Reading {
    /**
     * 
     * @type {string}
     * @memberof Reading
     */
    'logged'?: string;
    /**
     * 
     * @type {string}
     * @memberof Reading
     */
    'minMaxAvg'?: ReadingMinMaxAvgEnum;
    /**
     * 
     * @type {string}
     * @memberof Reading
     */
    'readingId'?: string;
    /**
     * 
     * @type {Sensor}
     * @memberof Reading
     */
    'sensor'?: Sensor;
    /**
     * 
     * @type {string}
     * @memberof Reading
     */
    'timePeriod'?: ReadingTimePeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof Reading
     */
    'value'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReadingMinMaxAvgEnum {
    Min = 'MIN',
    Max = 'MAX',
    Avg = 'AVG'
}
/**
    * @export
    * @enum {string}
    */
export enum ReadingTimePeriodEnum {
    All = 'ALL',
    Today = 'TODAY',
    Yesterday = 'YESTERDAY',
    Thisweek = 'THISWEEK',
    Thismonth = 'THISMONTH',
    Thisyear = 'THISYEAR',
    Lastday = 'LASTDAY',
    Lastweek = 'LASTWEEK',
    Lastmonth = 'LASTMONTH',
    Lastyear = 'LASTYEAR'
}

/**
 * 
 * @export
 * @interface ReportValue
 */
export interface ReportValue {
    /**
     * 
     * @type {string}
     * @memberof ReportValue
     */
    'logged'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportValue
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface RoomReading
 */
export interface RoomReading {
    /**
     * 
     * @type {string}
     * @memberof RoomReading
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReading
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReading
     */
    'logged'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReading
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReading
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'resetInterval'?: number;
    /**
     * 
     * @type {SensorClass}
     * @memberof Sensor
     */
    'sensorClass'?: SensorClass;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'sensorId'?: string;
    /**
     * 
     * @type {SensorType}
     * @memberof Sensor
     */
    'sensorType'?: SensorType;
    /**
     * 
     * @type {SourceType}
     * @memberof Sensor
     */
    'sourceType'?: SourceType;
}
/**
 * 
 * @export
 * @interface SensorClass
 */
export interface SensorClass {
    /**
     * 
     * @type {string}
     * @memberof SensorClass
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorClass
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorClass
     */
    'sensorClassId'?: string;
}
/**
 * 
 * @export
 * @interface SensorType
 */
export interface SensorType {
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'sensorTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface SourceType
 */
export interface SourceType {
    /**
     * 
     * @type {string}
     * @memberof SourceType
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceType
     */
    'sourceTypeId'?: string;
}
/**
 * 
 * @export
 * @interface TanksTemps
 */
export interface TanksTemps {
    /**
     * 
     * @type {Array<number>}
     * @memberof TanksTemps
     */
    'accTemps'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TanksTemps
     */
    'hpTemps'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof TanksTemps
     */
    'outside'?: number;
    /**
     * 
     * @type {string}
     * @memberof TanksTemps
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface TemperatureReport
 */
export interface TemperatureReport {
    /**
     * 
     * @type {ReportValue}
     * @memberof TemperatureReport
     */
    'current'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof TemperatureReport
     */
    'max'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof TemperatureReport
     */
    'min'?: ReportValue;
}
/**
 * 
 * @export
 * @interface TimeSerie
 */
export interface TimeSerie {
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof TimeSerie
     */
    'parameters'?: Array<Parameter>;
    /**
     * 
     * @type {string}
     * @memberof TimeSerie
     */
    'validTime'?: string;
}
/**
 * 
 * @export
 * @interface WeatherReport
 */
export interface WeatherReport {
    /**
     * 
     * @type {ReportValue}
     * @memberof WeatherReport
     */
    'barometer'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof WeatherReport
     */
    'dewPoint'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof WeatherReport
     */
    'humidity'?: ReportValue;
    /**
     * 
     * @type {RainReport}
     * @memberof WeatherReport
     */
    'rainReport'?: RainReport;
    /**
     * 
     * @type {TemperatureReport}
     * @memberof WeatherReport
     */
    'temperatureReport'?: TemperatureReport;
    /**
     * 
     * @type {WindReport}
     * @memberof WeatherReport
     */
    'windReport'?: WindReport;
}
/**
 * 
 * @export
 * @interface WindReport
 */
export interface WindReport {
    /**
     * 
     * @type {ReportValue}
     * @memberof WindReport
     */
    'current'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof WindReport
     */
    'max'?: ReportValue;
    /**
     * 
     * @type {ReportValue}
     * @memberof WindReport
     */
    'min'?: ReportValue;
}

/**
 * ChartControllerApi - axios parameter creator
 * @export
 */
export const ChartControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getDatasetBySensorClass
         * @param {'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER'} sensorClass sensorClass
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetBySensorClassUsingGET: async (sensorClass: 'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensorClass' is not null or undefined
            assertParamExists('getDatasetBySensorClassUsingGET', 'sensorClass', sensorClass)
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('getDatasetBySensorClassUsingGET', 'timePeriod', timePeriod)
            const localVarPath = `/chart/{timePeriod}/byclass/{sensorClass}`
                .replace(`{${"sensorClass"}}`, encodeURIComponent(String(sensorClass)))
                .replace(`{${"timePeriod"}}`, encodeURIComponent(String(timePeriod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDatasetBySensorType
         * @param {'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND'} sensorType sensorType
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetBySensorTypeUsingGET: async (sensorType: 'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensorType' is not null or undefined
            assertParamExists('getDatasetBySensorTypeUsingGET', 'sensorType', sensorType)
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('getDatasetBySensorTypeUsingGET', 'timePeriod', timePeriod)
            const localVarPath = `/chart/{timePeriod}/bytype/{sensorType}`
                .replace(`{${"sensorType"}}`, encodeURIComponent(String(sensorType)))
                .replace(`{${"timePeriod"}}`, encodeURIComponent(String(timePeriod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDatasetBySensor
         * @param {string} sensorId sensorId
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetBySensorUsingGET: async (sensorId: string, timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensorId' is not null or undefined
            assertParamExists('getDatasetBySensorUsingGET', 'sensorId', sensorId)
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('getDatasetBySensorUsingGET', 'timePeriod', timePeriod)
            const localVarPath = `/chart/{timePeriod}/bysensor/{sensorId}`
                .replace(`{${"sensorId"}}`, encodeURIComponent(String(sensorId)))
                .replace(`{${"timePeriod"}}`, encodeURIComponent(String(timePeriod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getYearByYearBySensors
         * @param {string} sensorId sensorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearByYearBySensorsUsingGET: async (sensorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensorId' is not null or undefined
            assertParamExists('getYearByYearBySensorsUsingGET', 'sensorId', sensorId)
            const localVarPath = `/chart/yearbyyear/{sensorId}`
                .replace(`{${"sensorId"}}`, encodeURIComponent(String(sensorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartControllerApi - functional programming interface
 * @export
 */
export const ChartControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getDatasetBySensorClass
         * @param {'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER'} sensorClass sensorClass
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetBySensorClassUsingGET(sensorClass: 'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetBySensorClassUsingGET(sensorClass, timePeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDatasetBySensorType
         * @param {'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND'} sensorType sensorType
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetBySensorTypeUsingGET(sensorType: 'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetBySensorTypeUsingGET(sensorType, timePeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDatasetBySensor
         * @param {string} sensorId sensorId
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetBySensorUsingGET(sensorId: string, timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetBySensorUsingGET(sensorId, timePeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getYearByYearBySensors
         * @param {string} sensorId sensorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYearByYearBySensorsUsingGET(sensorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYearByYearBySensorsUsingGET(sensorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChartControllerApi - factory interface
 * @export
 */
export const ChartControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getDatasetBySensorClass
         * @param {'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER'} sensorClass sensorClass
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetBySensorClassUsingGET(sensorClass: 'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: any): AxiosPromise<ChartConfig> {
            return localVarFp.getDatasetBySensorClassUsingGET(sensorClass, timePeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDatasetBySensorType
         * @param {'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND'} sensorType sensorType
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetBySensorTypeUsingGET(sensorType: 'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: any): AxiosPromise<ChartConfig> {
            return localVarFp.getDatasetBySensorTypeUsingGET(sensorType, timePeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDatasetBySensor
         * @param {string} sensorId sensorId
         * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetBySensorUsingGET(sensorId: string, timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: any): AxiosPromise<ChartConfig> {
            return localVarFp.getDatasetBySensorUsingGET(sensorId, timePeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getYearByYearBySensors
         * @param {string} sensorId sensorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearByYearBySensorsUsingGET(sensorId: string, options?: any): AxiosPromise<ChartConfig> {
            return localVarFp.getYearByYearBySensorsUsingGET(sensorId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChartControllerApi - object-oriented interface
 * @export
 * @class ChartControllerApi
 * @extends {BaseAPI}
 */
export class ChartControllerApi extends BaseAPI {
    /**
     * 
     * @summary getDatasetBySensorClass
     * @param {'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER'} sensorClass sensorClass
     * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getDatasetBySensorClassUsingGET(sensorClass: 'BRINE' | 'HEATING_ACC' | 'HEATING_ACC_HP' | 'HEATING_FH' | 'HEATING_FP_ACC' | 'HEATING_HP' | 'OUTSIDE' | 'ROOM' | 'SOLAR' | 'WATER', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getDatasetBySensorClassUsingGET(sensorClass, timePeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDatasetBySensorType
     * @param {'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND'} sensorType sensorType
     * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getDatasetBySensorTypeUsingGET(sensorType: 'DEWPOINT' | 'ENERGY' | 'HUMIDITY' | 'LUMINISCENCE' | 'POWER' | 'PRESSURE' | 'RAIN' | 'RAINRATE' | 'TEMPERATURE' | 'WIND', timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getDatasetBySensorTypeUsingGET(sensorType, timePeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDatasetBySensor
     * @param {string} sensorId sensorId
     * @param {'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR'} timePeriod timePeriod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getDatasetBySensorUsingGET(sensorId: string, timePeriod: 'ALL' | 'TODAY' | 'YESTERDAY' | 'THISWEEK' | 'THISMONTH' | 'THISYEAR' | 'LASTDAY' | 'LASTWEEK' | 'LASTMONTH' | 'LASTYEAR', options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getDatasetBySensorUsingGET(sensorId, timePeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getYearByYearBySensors
     * @param {string} sensorId sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartControllerApi
     */
    public getYearByYearBySensorsUsingGET(sensorId: string, options?: AxiosRequestConfig) {
        return ChartControllerApiFp(this.configuration).getYearByYearBySensorsUsingGET(sensorId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomControllerApi - axios parameter creator
 * @export
 */
export const RoomControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getRoomReading
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomReadingUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomControllerApi - functional programming interface
 * @export
 */
export const RoomControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getRoomReading
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoomReadingUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomReading>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomReadingUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomControllerApi - factory interface
 * @export
 */
export const RoomControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getRoomReading
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomReadingUsingGET(options?: any): AxiosPromise<Array<RoomReading>> {
            return localVarFp.getRoomReadingUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomControllerApi - object-oriented interface
 * @export
 * @class RoomControllerApi
 * @extends {BaseAPI}
 */
export class RoomControllerApi extends BaseAPI {
    /**
     * 
     * @summary getRoomReading
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public getRoomReadingUsingGET(options?: AxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).getRoomReadingUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SmhiControllerApi - axios parameter creator
 * @export
 */
export const SmhiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getForecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecastUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SmhiControllerApi - functional programming interface
 * @export
 */
export const SmhiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SmhiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getForecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForecastUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Forecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForecastUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SmhiControllerApi - factory interface
 * @export
 */
export const SmhiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SmhiControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getForecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecastUsingGET(options?: any): AxiosPromise<Forecast> {
            return localVarFp.getForecastUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SmhiControllerApi - object-oriented interface
 * @export
 * @class SmhiControllerApi
 * @extends {BaseAPI}
 */
export class SmhiControllerApi extends BaseAPI {
    /**
     * 
     * @summary getForecast
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmhiControllerApi
     */
    public getForecastUsingGET(options?: AxiosRequestConfig) {
        return SmhiControllerApiFp(this.configuration).getForecastUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TankControllerApi - axios parameter creator
 * @export
 */
export const TankControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary tanktempdataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tanktempdatasetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tanktempdataset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TankControllerApi - functional programming interface
 * @export
 */
export const TankControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TankControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary tanktempdataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tanktempdatasetUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TanksTemps>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tanktempdatasetUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TankControllerApi - factory interface
 * @export
 */
export const TankControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TankControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary tanktempdataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tanktempdatasetUsingGET(options?: any): AxiosPromise<Array<TanksTemps>> {
            return localVarFp.tanktempdatasetUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TankControllerApi - object-oriented interface
 * @export
 * @class TankControllerApi
 * @extends {BaseAPI}
 */
export class TankControllerApi extends BaseAPI {
    /**
     * 
     * @summary tanktempdataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TankControllerApi
     */
    public tanktempdatasetUsingGET(options?: AxiosRequestConfig) {
        return TankControllerApiFp(this.configuration).tanktempdatasetUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WeatherControllerApi - axios parameter creator
 * @export
 */
export const WeatherControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCurrentWeatherData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentWeatherDataUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weather/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWeatherReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeatherReportUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weather/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeatherControllerApi - functional programming interface
 * @export
 */
export const WeatherControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeatherControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getCurrentWeatherData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentWeatherDataUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reading>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentWeatherDataUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getWeatherReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeatherReportUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeatherReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeatherReportUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeatherControllerApi - factory interface
 * @export
 */
export const WeatherControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeatherControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getCurrentWeatherData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentWeatherDataUsingGET(options?: any): AxiosPromise<Array<Reading>> {
            return localVarFp.getCurrentWeatherDataUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getWeatherReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeatherReportUsingGET(options?: any): AxiosPromise<WeatherReport> {
            return localVarFp.getWeatherReportUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeatherControllerApi - object-oriented interface
 * @export
 * @class WeatherControllerApi
 * @extends {BaseAPI}
 */
export class WeatherControllerApi extends BaseAPI {
    /**
     * 
     * @summary getCurrentWeatherData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherControllerApi
     */
    public getCurrentWeatherDataUsingGET(options?: AxiosRequestConfig) {
        return WeatherControllerApiFp(this.configuration).getCurrentWeatherDataUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getWeatherReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherControllerApi
     */
    public getWeatherReportUsingGET(options?: AxiosRequestConfig) {
        return WeatherControllerApiFp(this.configuration).getWeatherReportUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}



import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { WeatherReport } from '../generated';
import { Box, Button, CardActions, Grid } from '@mui/material';
import ReactCardFlip from 'react-card-flip';
import { SimpleLineChart } from './SimpleLineChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useState } from 'react';


interface OtherInfoCardProps {
    weatherReport: WeatherReport;
}

export function OtherInfoCard(props: OtherInfoCardProps) {
    const { barometer, humidity, dewPoint } = props.weatherReport;
    const [isFlipped, flip] = useState(false);
    const handleClick = (e: any) => {
        flip(!isFlipped)
    }
    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Mer väderdata
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary" paragraph>
                            Barometer: {barometer?.value}<br />
                            Luftfuktighet: {humidity?.value}<br />
                            Daggpunkt: {dewPoint?.value}

                        </Typography>
                    </Box>
                </CardContent>
                <CardActions><Button onClick={handleClick}><ShowChartIcon /></Button></CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Box height="40vh" width="100%" display="flex" flexDirection="column">
                        <SimpleLineChart timePeriod='LASTWEEK' byType='bysensor' sensorId='ZWayVDev_zway_4-0-49-2' />
                    </Box>
                </CardContent>
                <CardActions><Button onClick={handleClick}><ArrowBackIosNewIcon /></Button></CardActions>
            </Card>
        </ReactCardFlip>

    );
}
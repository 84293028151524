import { useEffect, useState, ReactText } from 'react';
import moment from 'moment';
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend,
} from 'recharts';
import { AxiosResponse } from 'axios';

import { ChartConfig, ChartData } from '../generated';
import { chartApi } from '../util/apis';
import { getColorFromRange } from '../util/ColorUtil';
import Slider from '@mui/material/Slider';


interface YearByYearChartProps {
  sensor: string,
}

export const YearByYearChart: React.FC<YearByYearChartProps> = (props) => {
  const [dataKeys, setDatakeys] = useState<string[]>([])
  const [chartData, setData] = useState<ChartData[]>([])
  useEffect(() => {
    chartApi.getYearByYearBySensorsUsingGET(props.sensor).then((response: AxiosResponse<ChartConfig>) => {
      setDatakeys(response.data.keys!)
      setData(response.data.chartData!)
    })
  }, [props.sensor]);
  const getDataKey = (index: number) => {
    return 'data[' + index + ']'
  }
  const getkW = (w: string | number | ReactText[]) => {
    return w;
  }
  const getMW = (w: number) => {
    return "" + (w / 1000000);
  }
  const [sliderValues, setValue] = useState([1, 365]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const valueLabelFormat = (value: number) => {
    if (chartData[value]) {
      const unixTime = chartData[value].timestamp;
      return moment(unixTime).format('D/MM')
    } else {
      return value
    }

  }
  return (
    <div style={{ width: '100%', height: 310 }}>
      <ResponsiveContainer>
        <LineChart data={chartData.filter((key, index) => sliderValues[0] <= index && index <= sliderValues[1])}
          margin={{
            top: 0, right: 0, left: -25, bottom: 0,
          }}>
          <XAxis scale="time" type="number" dataKey="timestamp" domain={['auto', 'auto']}
            tickFormatter={(unixTime) => moment(unixTime).format('D/MM')} />
          <YAxis tickFormatter={(value, index) => getMW(value)} />
          <Tooltip formatter={(w: number) => getkW(w)} labelFormatter={(unixTime) => moment(unixTime).format('D/MM')} />
          <Legend />
          {dataKeys.map((key, index) => (<Line unit="W" name={key} key={key} type="monotone" dataKey={getDataKey(index)} stroke={getColorFromRange(index, dataKeys.length)} dot={false} />))}
        </LineChart>
      </ResponsiveContainer>
      <Slider
        value={sliderValues}
        valueLabelFormat={valueLabelFormat}
        max={chartData.length - 1}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        onChange={handleChange}
      />
    </div>
  );
}

